import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './Header';
import Inicio from './pages/Inicio.js';
import QuienesSomos from './pages/QuienesSomos.js';
import Contacto from './pages/Contacto';
import './App.css'; // o './App.css'


import { Carousel } from 'react-bootstrap';

// Añadir un ícono de WhatsApp al final de la página
function WhatsAppButton() {
  return (
    <a
      href="https://wa.me/56993303890?text=Hola,%20quiero%20más%20información%20sobre%20sus%20servicios."
      className="whatsapp-btn"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg" alt="WhatsApp" />
    </a>
  );
}

function App() {
  return (
    <Router>
      <div className="App">
        <Header />

        {/* Rutas */}
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Carousel interval={3000} fade>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src={process.env.PUBLIC_URL + '/imagen/carrusel-2.jpg'}
                      alt="First slide"
                    />
                  </Carousel.Item>

                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src={process.env.PUBLIC_URL + '/imagen/carrusel-fyp.jpg'}
                      alt="Second slide"
                    />
                  </Carousel.Item>

                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src={process.env.PUBLIC_URL + '/imagen/FYP.jpg'}
                      alt="Third slide"
                    />
                  </Carousel.Item>
                </Carousel>

                <Inicio />
              </>
            }
          />

          <Route path="/quienes-somos" element={<QuienesSomos />} />
          <Route path="/contacto" element={<Contacto />} />
        </Routes>

       {/* 
        <div className="cta-container">
          <a href="/contacto" className="boton-cotizacion">Solicitar Cotización</a>
        </div>*/}

        {/* Botón de WhatsApp */}
        <WhatsAppButton />
      </div>
    </Router>
  );
}

export default App;
