import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Contacto.css'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; 
import { faComments, faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';

function Contacto() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [submitStatus, setSubmitStatus] = useState(null);
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();

    if (name && email && message) {
      fetch('https://fypaseo.cl/sendEmail.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
          name: name,
          email: email,
          message: message
        })
      })
        .then((response) => response.text())
        .then((result) => {
          if (result === "success") {
            setSubmitStatus('success');
            alert('Mensaje enviado con éxito');
            setName('');
            setEmail('');
            setMessage('');
            navigate('/gracias'); 
          } else {
            setSubmitStatus('error');
            alert('Error al enviar el mensaje');
          }
        })
        .catch(() => {
          setSubmitStatus('error');
          alert('Error al enviar el mensaje');
        });
    } else {
      setSubmitStatus('error');
      alert('Por favor, rellena todos los campos.');
    }
  };

  return (
    <div className="contacto-page">
      <div className="contacto-container">
        <h1>Contáctanos</h1>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Nombre:</label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              placeholder="Ingresa tu nombre"
            />
          </div>
          <div className="form-group">
            <label>Email:</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              placeholder="Ingresa tu email"
            />
          </div>
          <div className="form-group">
            <label>Mensaje:</label>
            <textarea
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
              placeholder="Escribe tu mensaje"
            />
          </div>
          <button
            type="submit"
            className={`btn btn-submit ${submitStatus === 'success' ? 'btn-success' : ''} ${submitStatus === 'error' ? 'btn-error' : ''}`}
          >
            Enviar
          </button>
        </form>
      </div>

      {/* Sección de Conversemos */}
      <div className="conversemos-container">
        <div className="conversemos-box">
          <FontAwesomeIcon icon={faComments} size="3x" />
          <h2>Conversemos</h2>
          <p>
            <FontAwesomeIcon icon={faPhone} />{" "}
            <a href="https://wa.me/56993303890" target="_blank" rel="noopener noreferrer">
              +56 9 9330 3890
            </a>
            <br />
            <FontAwesomeIcon icon={faEnvelope} />{" "}
            <a href="mailto:Contacto@fypaseo.cl">Contacto@fypaseo.cl</a>
          </p>
        </div>
        <div className="conversemos-box">
          <FontAwesomeIcon icon={faComments} size="3x" />
          <h2>Conversemos</h2>
          <p>
            Yasmiris Parra 
            <br />Gerente de Administración y Recursos Humanos
            <br />
            <FontAwesomeIcon icon={faPhone} />{" "}
            <a href="https://wa.me/56949137923" target="_blank" rel="noopener noreferrer">
              +56 9 4913 7923
            </a>
            <br />
            <FontAwesomeIcon icon={faEnvelope} />{" "}
            <a href="mailto:Yasmiris.parra@fypaseo.cl">Yasmiris.parra@fypaseo.cl</a>
          </p>
        </div>
        <div className="conversemos-box">
          <FontAwesomeIcon icon={faComments} size="3x" />
          <h2>Conversemos</h2>
          <p>
            Jimmy Fuentes 
            <br />Gerente de Operaciones
            <br />
            <FontAwesomeIcon icon={faPhone} />{" "}
            <a href="https://wa.me/56920286969" target="_blank" rel="noopener noreferrer">
              +56 9 2028 6969
            </a>
            <br />
            <FontAwesomeIcon icon={faEnvelope} />{" "}
            <a href="mailto:Jimmy.fuentes@fypaseo.cl">Jimmy.fuentes@fypaseo.cl</a>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Contacto;
